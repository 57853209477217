import { AxiosResponse } from "axios";

import api from "@/api";

import Event from "@/types/Event";

export interface GetEventsResponse {
  data: Event[];
}

async function getEvents(page = 1, producer = "0", category = ""): Promise<AxiosResponse<GetEventsResponse>> {
  return await api.get<GetEventsResponse>(`/api/events?page=${page}&producer=${producer}&category=${category}`);
}

export default getEvents;
