import { AxiosResponse } from "axios";

import api from "@/api";

import Banner from "@/types/Banner";

export interface GetBannersResponse {
  data: Banner[];
}

async function getBanners(producerId: string | null = null): Promise<Banner[]> {
  const params = {} as Record<string, string>;

  if (producerId !== "0" && producerId !== null) {
    params["producer_id"] = producerId;
  }

  const response: AxiosResponse<Banner[]> = await api.get(`api/banners`, {
    params,
  });

  return response.data;
}
export default getBanners;
