



































import Vue from "vue";

import TEventCard from "@/components/core/Events/TEventCard.vue";

import getEvents from "@/api/requests/getEvents";

import Event from "@/types/Event";
import Category from "@/types/Category";
import TSelect from "../core/TSelect.vue";
import getCategories from "@/api/requests/getCategories";

export default Vue.extend({
  name: "SectionNextEvents",

  components: {
    TEventCard,
    TSelect,
  },

  data: () => ({
    eventsPerPage: 50,
    page: 1,
  }),

  computed: {
    pageCount(): number {
      return Math.ceil(this.events.length / this.eventsPerPage);
    },
    events(): Event[] {
      return this.$store.state.events.data;
    },
    categories(): Category[] {
      return this.$store.state.categories.data;
    },
  },

  mounted() {
    this.fetch();
    this.fetchCategories();
  },

  methods: {
    async fetch(page = 1, category = "") {
      const producer = this.$store.state.producers.selected;

      const events = (await getEvents(page, producer.id, category)).data.data;

      this.$store.commit("events/setEvents", events);
    },
    async fetchCategories() {
      const categories = await getCategories();

      this.$store.commit("categories/setCategories", categories);
    },
  },
});
