




















import Vue from "vue";

import getBanners from "@/api/requests/getBanners";
import Banner from "@/types/Banner";

export default Vue.extend({
  name: "TImagesCarousel",

  data() {
    return {
      slides: [] as Banner[],
    };
  },

  computed: {
    isMobile(): boolean {
      return this.$vuetify.breakpoint.xs;
    },
    platform(): string {
      if (this.isMobile) return "_mobile";
      else return "_desktop";
    },
  },

  async mounted() {
    const producer = this.$store.state.producers.selected;

    this.slides = await getBanners(producer.id);
  },
});
