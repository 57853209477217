







import Vue from "vue";

import SectionNextEvents from "@/components/Home/SectionNextEvents.vue";
import TImagesCarousel from "@/components/Home/TImagesCarousel.vue";

export default Vue.extend({
  name: "HomePage",

  components: {
    SectionNextEvents,
    TImagesCarousel,
  },

  mounted() {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 500);
  },

  data: () => ({}),
});
