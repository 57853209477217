























































import Vue, { PropType } from "vue";
import { DateTime } from "luxon";
import TEventLogo from "@/components/core/Events/TEventLogo.vue";

import Event from "@/types/Event";

export default Vue.extend({
  name: "TEventCard",

  components: {
    TEventLogo,
  },

  data() {
    return {
      luxon: DateTime,
    };
  },

  props: {
    event: {
      type: Object as PropType<Event>,
      required: true,
    },
  },

  methods: {
    share() {
      if (this.isMobile) {
        window.navigator.share({
          title: this.event.nome,
          url: `${window.location.protocol}//${window.location.hostname}/ticket/buy/${this.event.id}`,
        });
      } else {
        navigator.clipboard.writeText(`${window.location.origin}/ticket/buy/${this.event.id}`);
      }
    },
  },

  computed: {
    isMobile(): boolean {
      return this.$vuetify.breakpoint.xs;
    },
    status(): "aberto" | "futuro" | "encerrado" {
      const now = DateTime.now();
      const start = DateTime.fromFormat(this.event.inicio_venda_online, "y-MM-dd HH:mm:ss");
      const end = DateTime.fromFormat(this.event.termino_venda_online, "y-MM-dd HH:mm:ss");

      if (now < start) {
        return "futuro";
      } else if (now > end) {
        return "encerrado";
      } else {
        return "aberto";
      }
    },
    text(): string {
      return {
        aberto: "Comprar Ingressos",
        futuro: "Vendas em Breve",
        encerrado: "Vendas Encerradas",
      }[this.status];
    },
    color(): string {
      return {
        aberto: "green",
        futuro: "orange",
        encerrado: "red",
      }[this.status];
    },
    link(): string {
      return {
        aberto: `/ticket/buy/${this.event.id}`,
        futuro: "/",
        encerrado: "/",
      }[this.status];
    },
    address(): string {
      const local = this.event.configuration?.mapping.local;
      if (local) {
        return `${local.cidade} - ${local.estado}`;
      }
      return "";
    },
  },
});
