import api from "@/api";

import Category from "@/types/Category";
import DataResponse from "@/types/DataResponse";

async function getCategories(): Promise<Category[]> {
  const response = await api.get<DataResponse<Category[]>>(`/api/categories`);

  return response.data.data;
}

export default getCategories;
